<template>
  <div class="locale-changer">
    <router-link
      class="text-uppercase text-decoration-none small"
      @click.native="
        $i18n.locale = LangNotUsed;
        changeHTML();
      "
      :to="{ name: $route.name, params: { lang: LangNotUsed } }"
    >
      {{ LangNotUsed }}
    </router-link>
  </div>
</template>

<script>
export default {
  name: "LocaleChanger",
  computed: {
    LangNotUsed() {
      return this.$i18n.availableLocales.filter(
        (lang) => lang !== this.$i18n.locale
      )[0];
    },
  },
  mounted() {
    this.changeHTML();
  },
  methods: {
    changeHTML() {
      document.querySelector("html").setAttribute("lang", this.$i18n.locale);
    },
  },
};
</script>
