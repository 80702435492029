<template>
  <div class="w-100 text-start pb-5">
    <div
      :class="
        'form-control czeds-text-input d-flex align-items-center justify-content-between ' +
        inputClass +
        ' ' +
        (focus ? 'focus' : '') +
        (valid ? '' : ' invalid')
      "
    >
      <input
        ref="input"
        :type="type"
        :name="name"
        :placeholder="placeholder"
        :value="value"
        class="col border-0 bg-transparent"
        @input="$emit('input', $event.target.value)"
        :required="required"
        @focus="toggleFocus()"
        @blur="
          checkValidity();
          toggleFocus();
        "
      />
      <Icon
        v-if="!!icon"
        :name="icon"
        size="large"
        type="solid"
        class="col-auto"
      />
    </div>
    <div class="small mt-2 text-danger position-absolute" v-if="!valid">
      {{ $t("forms_errors.email.email") }}
    </div>
  </div>
</template>

<script>
  import Icon from "@/components/Icon.vue";

  export default {
    components: {
      Icon,
    },
    props: {
      value: [String, Number],
      type: String,
      name: String,
      placeholder: String,
      required: Boolean,
      inputClass: String,
      icon: String,
    },
    data() {
      return {
        valid: true,
        focus: false,
      };
    },
    methods: {
      toggleFocus() {
        this.focus = !this.focus;
      },
      checkValidity() {
        if (this.required || (!this.required && this.value !== "")) {
          this.valid = this.$refs.input.checkValidity();
          this.$emit("blur", { valid: this.$refs.input.checkValidity() });
        } else {
          this.valid = true;
        }
      },
    },
  };
</script>
