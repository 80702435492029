<template>
  <div class="coming-soon px-lg-6 px-3">
    <LocaleChanger class="fixed-top" />
    <div
      class="d-flex align-items-center flex-md-nowrap h-100 flex-md-row flex-column"
    >
      <div class="col-md-4 order-md-1 order-2 mt-n5 mt-md-0">
        <img
          class="half-image"
          :src="require('@/assets/images/homepage.png')"
          alt="basilbees illustration image"
        />
      </div>
      <div
        class="col text-center d-flex align-items-center justify-content-center order-md-2 order-1 mt-7 mt-md-0"
      >
        <div class="col-lg-10 col-12">
          <Logo :horizontal="false" size="auto" />
          <h1 class="mt-7 mb-2">{{ $t("comingSoon.title") }}</h1>
          <p v-html="$t('comingSoon.description')"></p>
          <div
            class="d-flex flex-wrap flex-md-nowrap align-items-start justify-content-center mt-3"
            v-if="!subscribed"
          >
            <Input
              v-model="email"
              type="email"
              name="email"
              :placeholder="$t('comingSoon.inputPlaceholder')"
              :required="false"
              inputClass="col"
              icon="user"
              @blur="valid = $event.valid"
            />
            <Button
              :label="$t('comingSoon.button')"
              size="large"
              class="col-auto ms-md-4"
              color="secondary"
              :loading="buttonLoading"
              @click="saveEmail()"
            />
          </div>
          <p class="fw-bold my-5" v-else>
            {{ $t("api.comingSoon.success") }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Logo from "@/components/Logo.vue";
import LocaleChanger from "@/components/LocaleChanger.vue";
import Input from "@/components/Input.vue";
import Button from "@/components/Button.vue";
import ComingSoonService from "@/services/ComingSoonService";

export default {
  name: "ComingSoon",
  components: {
    Logo,
    Button,
    LocaleChanger,
    Input,
  },
  data() {
    return {
      email: "",
      valid: true,
      buttonLoading: false,
      subscribed: false,
    };
  },
  methods: {
    saveEmail() {
      if (this.valid && this.email !== "") {
        this.buttonLoading = true;
        // add to a json file
        ComingSoonService.saveEmail({ email: this.email })
          .then((res) => {
            this.buttonLoading = false;
            this.subscribed = true;
            console.log(res);
          })
          .catch((err) => {
            this.buttonLoading = false;
            console.log(err);
          });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
.coming-soon {
  height: 100vh;
  overflow: hidden;
  background-color: $primary;
  .locale-changer {
    right: map-get($spacers, 4);
    top: map-get($spacers, 4);
    left: auto;
  }
}
.half-image {
  max-height: 70vw;
  transform: translateX(-50%);
  @media screen and (max-width: 767px) {
    max-height: 100vw;
    transform: translateX(0) translateY(10vh);
  }
}
</style>
